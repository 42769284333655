import {FC, useEffect, useMemo, useState} from 'react'
import {ListViewProvider, useListView} from '../../modules/view/ListViewProvider'
import {KTCard} from '../../../_metronic/helpers'

import {QueryRequestProvider} from '../../modules/view/QueryRequestProvider'
import {QueryResponseProvider} from '../../modules/view/QueryResponseProvider'
import {DashboardHeader} from './components/header/DashboardHeader'
import {TasksTable} from '../tasks/table/TasksTable'
import {DashboardCardStep} from './components/cardStep/DashboardCardStep'
import {Card, Col, Row, Spinner} from 'react-bootstrap'
import {DashboardTotalSales} from './components/totalSales/DashboardTotalSales'
import {DashboardBestUsers} from './components/bestUsers/DashboardBestUsers'
import {DashboardChart} from './components/chart/DashboardChart'
import {DashboardCardDuration} from './components/cardDay/DashboardCardDuration'
import {useLocation} from 'react-router-dom'
import {DashboardCategory} from './components/category/DashboardCategory'
import {useQuery} from 'react-query'
import {getFunnels} from '../funnel/core/_requests'
import {useFunnelDispatch} from '../funnel/core/FunnelContext'
import Swal from 'sweetalert2'
import {getDashboardOpportunities, getDashboardTickets} from './core/_requests'
import {DashboardResponseOpportunity, DashboardResponseTickets} from './core/_models'

type IDashboard = {
  dataTicket?: DashboardResponseTickets | null
  dataOpportunity?: DashboardResponseOpportunity | null
  ticket: boolean
  funnel: any
}
const spinnerStyle = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const spinnerTextStyle = {
  color: '#93a4ee',
  fontWeight: 'bold',
  padding: 50,
  marginBottom: 50,
}

const DashboardPage = ({dataOpportunity, dataTicket, ticket, funnel}: IDashboard) => {
  if (dataOpportunity) {
    //console.log(dataOpportunity)
    return (
      <>
        <Row>
          <Col sm='7' style={{display: 'flex', flexDirection: 'column'}}>
            <Row>
              <Col sm='12'>
                <DashboardTotalSales ticket={ticket} data={dataOpportunity.totalSales} />
              </Col>
            </Row>

            <Row
              style={{flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 10}}
            >
              <Col sm='12'>
                <DashboardCardDuration
                  ticket={ticket}
                  data={dataOpportunity.durationOfBusinessInMinutes}
                />
              </Col>
            </Row>
          </Col>

          <Col sm='5'>
            <DashboardChart
              ticket={ticket}
              data={dataOpportunity.yearSales}
              funnelId={funnel.funnelId}
            />
          </Col>
        </Row>

        <Row className='mt-6'>
          <Col sm='7'>
            <DashboardCardStep
              funnelName={funnel.funnelName}
              data={dataOpportunity.saleFunnelData}
              ticket={ticket}
            />
          </Col>
          <Col sm='5'>
            <DashboardBestUsers data={dataOpportunity.bestSellers} ticket={ticket} />
          </Col>
        </Row>
      </>
    )
  }
  if (dataTicket) {
    //console.log(dataTicket)
    return (
      <>
        <Row>
          <Col sm='7'>
            <DashboardCardStep
              funnelName={funnel.funnelName}
              data={dataTicket.ticketFunnelData}
              ticket={ticket}
            />
          </Col>

          <Col sm='5'>
            <DashboardChart
              ticket={ticket}
              data={dataTicket.yearAttendances}
              funnelId={funnel.funnelId}
            />
          </Col>
        </Row>

        <Row className=' mt-6 mb-1'>
          <Col sm='7' style={{display: 'flex', flexDirection: 'column'}}>
            <Row>
              <Col sm='12'>
                <DashboardCategory data={dataTicket.categories} />
              </Col>
            </Row>

            <Row style={{flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end'}}>
              <Col sm='12'>
                <DashboardCardDuration
                  ticket={ticket}
                  data={dataTicket.durationOfBusinessInMinutes}
                />
              </Col>
            </Row>
          </Col>

          <Col sm='5'>
            <DashboardBestUsers data={dataTicket.bestSolvers} ticket={ticket} />
          </Col>
        </Row>
      </>
    )
  }
  return (
    <div style={spinnerStyle}>
      <h3 style={spinnerTextStyle}>
        Ainda não há funis vinculados ao usuário para exibição do dashboard.{' '}
      </h3>
    </div>
  )
}

type IReqData = {
  funnelId: number | null
  funnelName: ''
  createdAtStart: Date | null | string
  createdAtEnd: Date | null | string
  finishDateStart: Date | null | string
  finishDateEnd: Date | null | string
}

function getFirstDayOfMonth() {
  const date = new Date()
  date.setDate(1)
  return date
}

function getLastDayOfMonth() {
  const date = new Date()
  date.setMonth(date.getMonth() + 1)
  date.setDate(0)
  return date
}

const DashboardWrapper: FC = () => {
  const location = useLocation()
  const [reqData, setReqData] = useState<IReqData>({
    funnelId: null,
    funnelName: '',
    createdAtStart: getFirstDayOfMonth(),
    createdAtEnd: getLastDayOfMonth(),
    finishDateStart: getFirstDayOfMonth(),
    finishDateEnd: getLastDayOfMonth(),
  })
  const [loading, setLoading] = useState(false)
  const [funnels, setFunnels] = useState<any[]>([])
  const [dashboardOpportunityData, setDashboardOpportunityData] =
    useState<DashboardResponseOpportunity | null>(null)
  const [dashboardTicketData, setDashboardTicketData] = useState<DashboardResponseTickets | null>(
    null
  )

  const isTicketAnalysis = location.pathname.includes('ticket-analysis')

  useEffect(() => {
    async function fetchFunnels() {
      try {
        let resFunnels = await getFunnels(
          `page=1&perPage=200&status=1&include_steps=true&type=${isTicketAnalysis ? '2' : '1'}`
        )
        if (resFunnels.length > 0) {
          const shortFunnels = resFunnels.map((e) => ({label: e.name, value: e.id}))
          setFunnels(shortFunnels)

          // validate if the local storage funnel is in the list
          const storageFunnel = localStorage.getItem('tric-favorite-funnel-id')

          const shortFunnel =
            shortFunnels.find((f: any) => f.value == storageFunnel) || shortFunnels[0]

          setReqData((prevData: any) => ({
            ...prevData,
            funnelId: shortFunnel.value,
            funnelName: shortFunnel.label,
          }))
        } else {
          setFunnels([])
          setReqData((prevData: any) => ({
            ...prevData,
            funnelId: null,
            funnelName: '',
          }))
        }
      } catch (error) {
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao carregar os funis.',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
    }

    fetchFunnels()
  }, [isTicketAnalysis])

  const convertDateToString = (dateStr: any, isEnd: boolean) => {
    const date = new Date(dateStr)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const time = isEnd ? '23:59:59' : '00:00:00'
    return `${year}-${month}-${day} ${time}`
  }
  const loadData = async () => {
    setLoading(true)

    let {funnelId, createdAtStart, createdAtEnd, finishDateEnd, finishDateStart} = reqData
    let queryString = ''

    if (!createdAtStart && !createdAtEnd) {
      const date = new Date()

      const start = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-01 00:00:00`
      const end = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
        date.getDate()
      ).padStart(2, '0')} 23:59:59`

      queryString = `funnelId=${funnelId}&createdAtStart=${start}&createdAtEnd=${end}`
    } else {
      createdAtStart = convertDateToString(createdAtStart, false)
      createdAtEnd = convertDateToString(createdAtEnd, true)
      queryString = `funnelId=${funnelId}&createdAtStart=${createdAtStart}&createdAtEnd=${createdAtEnd}`
    }
    if (finishDateStart && finishDateEnd) {
      finishDateStart = convertDateToString(finishDateStart, false)
      finishDateEnd = convertDateToString(finishDateEnd, true)
      queryString += `&finishDateStart=${finishDateStart}&finishDateEnd=${finishDateEnd}`
    }

    try {
      if (isTicketAnalysis) {
        const result: DashboardResponseTickets = await getDashboardTickets(queryString)
        setDashboardTicketData(result)
        setDashboardOpportunityData(null)
      } else {
        const result: DashboardResponseOpportunity = await getDashboardOpportunities(queryString)
        setDashboardOpportunityData(result)
        setDashboardTicketData(null)
      }

      setLoading(false)
    } catch (ex: any) {
      setLoading(false)
      console.error(ex.response.data)
      let errorDetected = ''

      if (ex.response?.data?.message) {
        errorDetected = ex.response?.data?.message
      }

      Swal.fire({
        title: 'Opss..',
        text: errorDetected ? errorDetected : 'Houve um problema ao carregar Dashboard.',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  useEffect(() => {
    if (reqData?.funnelId) loadData()
  }, [reqData?.funnelId])

  return (
    <>
      <QueryRequestProvider>
        <ListViewProvider>
          <DashboardHeader
            setReqData={setReqData}
            loadData={loadData}
            loading={loading}
            reqData={reqData}
            funnels={funnels}
          />

          {loading ? (
            <div style={spinnerStyle}>
              <Spinner style={spinnerTextStyle} />
            </div>
          ) : !isTicketAnalysis ? (
            <DashboardPage
              ticket={false}
              dataOpportunity={dashboardOpportunityData}
              funnel={reqData}
            />
          ) : (
            <DashboardPage ticket={true} dataTicket={dashboardTicketData} funnel={reqData} />
          )}
        </ListViewProvider>
      </QueryRequestProvider>
    </>
  )
}

export {DashboardWrapper}
