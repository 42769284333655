import {isAfter} from 'date-fns'
import {useEffect, useRef, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import StyledDatePicker from '../../../../modules/view/StyledDatePicker'
const UNITS_TIMEZONE = 'America/Sao_Paulo'

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none;
  }
`
type Icard = {
  loadData: any
  setReqData: any
  loading: boolean
  reqData: any
  funnels: any[]
}
const DashboardHeader = ({loadData, setReqData, reqData, loading, funnels}: Icard) => {
  const [calendar, setCalendar] = useState({
    createdStart: false,
    createdEnd: false,
    finishStart: false,
    finishEnd: false,
  })
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef: any = useRef(null)
  const createdStartRef: any = useRef(null)
  const createdEndRef: any = useRef(null)
  const finishStartRef: any = useRef(null)
  const finishEndRef: any = useRef(null)

  const onSubmit = async () => {
    const {createdAtStart, createdAtEnd, finishDateEnd, finishDateStart} = reqData

    let createdAtEndUtc: any = null
    let createdAtStartUtc: any = null
    let finishDateStartUtc: any = null
    let finishDateEndUtc: any = null

    if (!createdAtStart || !createdAtEnd) {
      return Swal.fire({
        title: 'Atenção',
        text: 'Ambas as datas, Data de Criação Início e Data de Criação Fim, devem ser fornecidas.',
        icon: 'warning',
        confirmButtonText: 'OK',
      })
    }

    if (isAfter(new Date(createdAtStart), new Date(createdAtEnd))) {
      return Swal.fire({
        title: 'Atenção',
        text: 'Data de Criação Início não pode ser maior do que Data de Criação Fim.',
        icon: 'warning',
        confirmButtonText: 'OK',
      })
    }

    if (finishDateEnd && finishDateStart) {
      if (isAfter(new Date(finishDateStart), new Date(finishDateEnd))) {
        return Swal.fire({
          title: 'Atenção',
          text: 'Data de Finalização Início não pode ser maior do que Data de Finalização Fim.',
          icon: 'warning',
          confirmButtonText: 'OK',
        })
      }
    }

    if (createdAtStart) {
      createdAtStartUtc = new Date(createdAtStart.toISOString())
    }
    if (createdAtEnd) {
      createdAtEndUtc = new Date(createdAtEnd.toISOString())
    }
    if (finishDateEnd) {
      finishDateEndUtc = new Date(finishDateEnd.toISOString())
    }
    if (finishDateStart) {
      finishDateStartUtc = new Date(finishDateStart.toISOString())
    }

    setReqData((prevData: any) => ({
      ...prevData,
      createdAtEnd: createdAtEndUtc,
      createdAtStart: createdAtStartUtc,
      finishDateStart: finishDateStartUtc,
      finishDateEnd: finishDateEndUtc,
    }))

    await loadData()
  }
  useEffect(() => {
    let timeoutId: any = null
    function handleClickOutside(event: any) {
      if (
        dropdownRef?.current &&
        !dropdownRef.current?.contains(event?.target) &&
        !createdStartRef.current?.contains(event?.target) &&
        !createdEndRef.current?.contains(event?.target) &&
        !finishStartRef.current?.contains(event?.target) &&
        !finishEndRef.current?.contains(event?.target)
      ) {
        //if para ve se onBlur foi fora do dropdown e se todos os calendarios estiverem fechados
        if (!Object.values(calendar).some((status) => status)) {
          timeoutId = setTimeout(() => {
            //delay pra nao dar bug visual
            setShowDropdown(false)
          }, 150)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [calendar])

  return (
    <div id='kt_app_toolbar' className='py-3 py-lg-6'>
      <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid'>
        <div
          id='kt_page_title'
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className='page-title d-flex flex-wrap me-3 flex-row justify-content-center'
        >
          <i className='fa-solid fa-filter fs-2 me-1'></i>
          <h3>
            <strong>Dashboard</strong>
          </h3>
        </div>

        <div className='d-flex align-items-center gap-2 gap-lg-3'>
          <InputFieldSet label={'Funil'}>
            <select
              name='funnel'
              data-control='select2'
              data-hide-search='true'
              className='form-control form-select form-select-sm w-200px fieldset-input'
              onChange={(e) => {
                setReqData((prevData: any) => ({
                  ...prevData,
                  funnelId: e.target.value,
                  funnelName: e.target.selectedOptions[0].text,
                }))

                localStorage.setItem('tric-favorite-funnel-id', e.target.value)
              }}
              disabled={loading || funnels?.length == 0}
              value={reqData.funnelId}
              defaultValue={reqData.funnelId}
            >
              {funnels?.map((el: any) => (
                <option key={el.value?.toString()} value={el.value?.toString()}>
                  {el.label}
                </option>
              ))}
            </select>
          </InputFieldSet>
          <div className='m-0'>
            <Dropdown ref={dropdownRef} id='dropdown-chat' show={showDropdown}>
              <StyledDropdownToggle
                variant='wl-custom-primary-collor-inverse'
                id='dropdown-basic'
                className='btn btn-sm me-3'
                onClick={() => setShowDropdown(!showDropdown)}
                disabled={loading || funnels?.length == 0}
              >
                <i className='fa-solid fa-filter fs-2'></i>
                Filtros
              </StyledDropdownToggle>

              <Dropdown.Menu className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'>
                <Dropdown.Header className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
                </Dropdown.Header>
                <div className='separator border-gray-200'></div>
                <div className='px-7 my-4' data-kt-user-table-filter='form'>
                  <div className='d-flex justify-content-between'>
                    <Dropdown.Item
                      className='px-0'
                      style={{width: '48%', backgroundColor: 'transparent'}}
                    >
                      <InputFieldSet label={'Data de Criação Início'}>
                        <StyledDatePicker
                          ref={createdStartRef}
                          value={reqData.createdAtStart}
                          onChange={(date: any) => {
                            setCalendar((prevState) => ({...prevState, createdStart: false}))
                            setReqData((prevData: any) => ({
                              ...prevData,
                              createdAtStart: date,
                            }))
                          }}
                          open={calendar.createdStart}
                          onOpen={() => {
                            setCalendar((prevState) => ({...prevState, createdStart: true}))
                          }}
                          onClose={() =>
                            setCalendar((prevState) => ({...prevState, createdStart: false}))
                          }
                        />
                      </InputFieldSet>
                    </Dropdown.Item>

                    <Dropdown.Item
                      className='px-0'
                      style={{width: '48%', backgroundColor: 'transparent'}}
                    >
                      <InputFieldSet label={'Data de Criação Fim'}>
                        <StyledDatePicker
                          ref={createdEndRef}
                          value={reqData.createdAtEnd}
                          onChange={(date: any) => {
                            setCalendar((prevState) => ({...prevState, createdEnd: false}))
                            setReqData((prevData: any) => ({
                              ...prevData,
                              createdAtEnd: date,
                            }))
                          }}
                          open={calendar.createdEnd}
                          onOpen={() => {
                            setCalendar((prevState) => ({...prevState, createdEnd: true}))
                          }}
                          onClose={() =>
                            setCalendar((prevState) => ({...prevState, createdEnd: false}))
                          }
                        />
                      </InputFieldSet>
                    </Dropdown.Item>
                  </div>

                  <div className='d-flex justify-content-between'>
                    <Dropdown.Item
                      className='px-0'
                      style={{width: '48%', backgroundColor: 'transparent'}}
                    >
                      <InputFieldSet label={'Data de Finalização Início'}>
                        <StyledDatePicker
                          ref={finishStartRef}
                          value={reqData.finishDateStart}
                          onChange={(date: any) => {
                            setCalendar((prevState) => ({...prevState, finishStart: false}))
                            setReqData((prevData: any) => ({
                              ...prevData,
                              finishDateStart: date,
                            }))
                          }}
                          open={calendar.finishStart}
                          onOpen={() =>
                            setCalendar((prevState) => ({...prevState, finishStart: true}))
                          }
                          onClose={() =>
                            setCalendar((prevState) => ({...prevState, finishStart: false}))
                          }
                        />
                      </InputFieldSet>
                    </Dropdown.Item>

                    <Dropdown.Item
                      className='px-0'
                      style={{width: '48%', backgroundColor: 'transparent'}}
                    >
                      <InputFieldSet label={'Data de Finalização Fim'}>
                        <StyledDatePicker
                          ref={finishEndRef}
                          value={reqData.finishDateEnd}
                          onChange={(date: any) => {
                            setCalendar((prevState) => ({...prevState, finishEnd: false}))
                            setReqData((prevData: any) => ({
                              ...prevData,
                              finishDateEnd: date,
                            }))
                          }}
                          open={calendar.finishEnd}
                          onOpen={() =>
                            setCalendar((prevState) => ({...prevState, finishEnd: true}))
                          }
                          onClose={() =>
                            setCalendar((prevState) => ({...prevState, finishEnd: false}))
                          }
                        />
                      </InputFieldSet>
                    </Dropdown.Item>
                  </div>
                </div>
                <div className='d-flex justify-content-end p-3 mt-2'>
                  <button
                    type='button'
                    onClick={() => {
                      setReqData((prevData: any) => ({
                        ...prevData,
                        createdAtEnd: null,
                        createdAtStart: null,
                        finishDateStart: null,
                        finishDateEnd: null,
                      }))
                    }}
                    className='btn btn-sm btn-wl-custom-secondary-collor fw-bold me-2 px-6'
                  >
                    Limpar
                  </button>
                  <button
                    type='button'
                    onClick={onSubmit}
                    className='btn btn-sm btn-wl-custom-primary-collor fw-bold px-6'
                  >
                    Aplicar
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DashboardHeader}
