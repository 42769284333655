import {createContext, useContext, useReducer} from 'react'

type StepCards = {
  stepId: number
  cards: Array<any>
  statistics: any
}

export interface FunnelContextModel {
  funnels: any
  currentFunnel: any | null
  currentStepsCards: Array<StepCards>
  loadingFunnelSteps: Boolean
  filters: any
}

const initialFunnelData: any = {
  funnels: [],
  currentFunnel: null,
  currentStepsCards: [],
  loadingFunnelSteps: false,
  filters: {},
}

const initialStatisticsData = {
  totalCards: 0,
  sumTotalValue: 0,
  sumRecurrentValue: 0,
  sumUniqueValue: 0,
}

const FunnelContext = createContext<FunnelContextModel>(initialFunnelData)
const FunnelDispatchContext = createContext<any>(null)

export function FunnelProvider({children}: any) {
  const [data, dispatch] = useReducer(funnelReducer, initialFunnelData)

  return (
    <FunnelContext.Provider value={data}>
      <FunnelDispatchContext.Provider value={dispatch}>{children}</FunnelDispatchContext.Provider>
    </FunnelContext.Provider>
  )
}

export function useFunnel() {
  return useContext(FunnelContext)
}

export function useFunnelDispatch() {
  return useContext(FunnelDispatchContext)
}

function funnelReducer(data: any, action: any) {
  // console.log("funnelReducer dispatch", action);
  //Se o usuário filtrado for diferente do responsável do evento WS, não fazer nada
  let responsibleIdFiltered = data?.filters?.responsible?.value
  if (responsibleIdFiltered && action?.type?.includes('step-card')) {
    let reponsibleEvent = action?.data?.responsible?.id
    if (reponsibleEvent != responsibleIdFiltered) return data
  }

  switch (action.type) {
    case 'init': {
      //Chamado apenas uma vez pra registrar os funnels

      let aux = {...data}

      aux.funnels = action.data.funnels

      aux.funnels.forEach((funnel: any) => {
        funnel.steps.sort((a: any, b: any) => a.stepNumber - b.stepNumber)
      })

      aux.currentFunnel = action.data.currentFunnel
      aux.currentStepsCards = []
      aux.loadingFunnelSteps = false

      //load currentStepsCards
      if (aux.currentFunnel) {
        aux.currentFunnel.steps.forEach((step: any) => {
          aux.currentStepsCards.push({
            stepId: step.id,
            cards: [],
            statistics: {...initialStatisticsData},
          })
        })
      }

      return aux
    }
    case 'start-loading-funnel-steps': {
      let aux = {...data}
      aux.loadingFunnelSteps = true
      return aux
    }
    case 'finish-loading-funnel-steps': {
      let aux = {...data}
      aux.loadingFunnelSteps = false
      return aux
    }
    case 'set-current-funnel-id': {
      let aux = {...data}

      if (!aux.funnels || aux.funnels.length == 0) return aux

      let newCurrentFunnel = aux.funnels.find((e: any) => e.id == action.data)

      if (!newCurrentFunnel) return aux

      aux.currentFunnel = newCurrentFunnel
      aux.currentStepsCards = []

      aux.currentFunnel.steps.forEach((step: any) => {
        aux.currentStepsCards.push({
          stepId: step.id,
          cards: [],
          statistics: {...initialStatisticsData},
        })
      })

      // Update localStorage if the current funnel ID is different
      const storedFunnelId = localStorage.getItem('tric-favorite-funnel-id')
      if (storedFunnelId !== String(newCurrentFunnel.id)) {
        localStorage.setItem('tric-favorite-funnel-id', String(newCurrentFunnel.id))
      }

      return aux
    }
    case 'set-current-steps-cards': {
      let aux = {...data}

      //Clear steps
      for (let i = 0; i < aux.currentStepsCards.length; i++) {
        let useStep = aux.currentStepsCards[i]
        useStep.cards = []
        useStep.statistics = {...initialStatisticsData}
      }

      for (let i = 0; i < action.data.length; i++) {
        let findStep = aux.currentStepsCards.find((e: any) => e.stepId == action.data[i].id)
        if (findStep) {
          findStep.cards = action.data[i].cards
          findStep.statistics = action.data[i].statistics
        }
      }

      if (aux.loadingFunnelSteps) aux.loadingFunnelSteps = false

      return aux
    }
    case 'update-step-card': {
      let aux = {...data}
      let step = aux.currentStepsCards.find((e: any) => e.stepId == action.data.funnelStepId)
      if (step) {
        let findOp = step.cards.find((e: any) => e.id == action.data.id)
        if (findOp) {
          //Update statistics
          if (step.statistics) {
            if (findOp.uniqueValue > 0) {
              step.statistics.sumUniqueValue -= findOp.uniqueValue
            }
            if (findOp.recurrentValue > 0) {
              step.statistics.sumRecurrentValue -= findOp.recurrentValue
            }

            if (action.data.uniqueValue > 0) {
              step.statistics.sumUniqueValue += action.data.uniqueValue
            }
            if (action.data.recurrentValue > 0) {
              step.statistics.sumRecurrentValue += action.data.recurrentValue
            }

            step.statistics.sumTotalValue =
              step.statistics.sumRecurrentValue + step.statistics.sumUniqueValue
          }

          Object.assign(findOp, action.data)
        }
        /*else
        {
          step.cards.push(action.data);
        }*/
      }
      return aux
    }
    case 'add-step-card': {
      let aux = {...data}
      let step = aux.currentStepsCards.find((e: any) => e.stepId == action.data.funnelStepId)
      if (step) {
        //Update statistics
        if (step.statistics) {
          if (action.data.uniqueValue > 0) {
            step.statistics.sumUniqueValue += action.data.uniqueValue
          }
          if (action.data.recurrentValue > 0) {
            step.statistics.sumRecurrentValue += action.data.recurrentValue
          }

          step.statistics.totalCards += 1
          step.statistics.sumTotalValue =
            step.statistics.sumRecurrentValue + step.statistics.sumUniqueValue
        }

        if (step?.cards?.length > 0) {
          step.cards = step.cards.filter((e: any) => e?.id != action?.data?.id)
        }

        step.cards.unshift(action.data)
      }
      return aux
    }
    case 'delete-step-card': {
      let aux = {...data}
      let step = aux.currentStepsCards.find((e: any) => e.stepId == action.data.funnelStepId)
      if (step) {
        let findOp = step.cards.find((e: any) => e.id == action.data.id)
        if (findOp) {
          //Update statistics
          if (step.statistics) {
            if (findOp.uniqueValue > 0) {
              step.statistics.sumUniqueValue -= findOp.uniqueValue
            }
            if (findOp.recurrentValue > 0) {
              step.statistics.sumRecurrentValue -= findOp.recurrentValue
            }

            step.statistics.totalCards -= 1
            step.statistics.sumTotalValue =
              step.statistics.sumRecurrentValue + step.statistics.sumUniqueValue
          }
        }

        step.cards = step.cards.filter((e: any) => e.id != action.data.id)
      }
      return aux
    }
    case 'move-step-card': {
      let aux = {...data}
      let oldStepId = action.oldStepId

      if (!oldStepId) {
        for (let i = 0; i < aux.currentStepsCards.length; i++) {
          let useStep = aux.currentStepsCards[i]
          let findCard = useStep.cards.find((e: any) => e?.id == action.data.id)
          if (findCard) {
            oldStepId = findCard.funnelStepId
            break
          }
        }
      }

      let oldStep = aux.currentStepsCards.find((e: any) => e.stepId == oldStepId)
      let new_step = aux.currentStepsCards.find((e: any) => e.stepId == action.data.funnelStepId)

      if (!oldStep || !new_step) return aux

      let useCardAction = oldStep.cards.find((e: any) => e.id == action.data.id)
      if (!useCardAction) return aux
      useCardAction.funnelStepId = action.data.funnelStepId

      oldStep.cards = oldStep.cards.filter((e: any) => e.id != action.data.id)
      //Update statistics oldStep
      if (oldStep.statistics) {
        if (useCardAction.uniqueValue > 0) {
          oldStep.statistics.sumUniqueValue -= useCardAction.uniqueValue
        }
        if (useCardAction.recurrentValue > 0) {
          oldStep.statistics.sumRecurrentValue -= useCardAction.recurrentValue
        }

        oldStep.statistics.totalCards -= 1
        oldStep.statistics.sumTotalValue =
          oldStep.statistics.sumRecurrentValue + oldStep.statistics.sumUniqueValue
      }

      new_step.cards.unshift(useCardAction)
      //Update statistics new_step
      if (new_step.statistics) {
        if (useCardAction.uniqueValue > 0) {
          new_step.statistics.sumUniqueValue += useCardAction.uniqueValue
        }
        if (useCardAction.recurrentValue > 0) {
          new_step.statistics.sumRecurrentValue += useCardAction.recurrentValue
        }

        new_step.statistics.totalCards += 1
        new_step.statistics.sumTotalValue =
          new_step.statistics.sumRecurrentValue + new_step.statistics.sumUniqueValue
      }

      return aux
    }
    case 'add-funnel-step': {
      let aux = {...data}

      if (!aux.currentFunnel) return aux

      const exists = aux.currentFunnel.steps.find((step: any) => step.id == action.data.id)

      if (exists) return aux

      aux.currentFunnel.steps.push(action.data)
      aux.currentFunnel.steps.sort((a: any, b: any) => a.stepNumber - b.stepNumber)

      aux.currentStepsCards.push({
        stepId: action.data.id,
        cards: [],
        statistics: {...initialStatisticsData},
      })

      //Alterar tbm na lista de funnels
      let findFunnel = aux.funnels.find((e: any) => e.id == aux.currentFunnel.id)
      if (findFunnel) {
        findFunnel.steps = aux.currentFunnel.steps
      }

      return aux
    }
    case 'move-funnel-step': {
      let aux = {...data}

      if (!aux.currentFunnel) return aux
      if (!action.data.newPosition || !action.data.id) return aux

      let findCurrentStep = aux.currentFunnel.steps.find((e: any) => e.id == action.data.id)
      if (!findCurrentStep) return aux

      let findStepNewPosition = aux.currentFunnel.steps.find(
        (e: any) => e.stepNumber == action.data.newPosition
      )
      if (findStepNewPosition) findStepNewPosition.stepNumber = findCurrentStep.stepNumber //Pode ser que não tenha etapa na nova posição

      findCurrentStep.stepNumber = action.data.newPosition //Update Position

      aux.currentFunnel.steps.sort((a: any, b: any) => a.stepNumber - b.stepNumber) //Reordenar

      //Alterar tbm na lista de funnels
      let findFunnel = aux.funnels.find((e: any) => e.id == aux.currentFunnel.id)
      if (findFunnel) {
        findFunnel.steps = aux.currentFunnel.steps
      }
      return aux
    }
    case 'update-funnel-step': {
      let aux = {...data}

      if (!aux.currentFunnel) return aux

      let currentStep = aux.currentFunnel.steps.find((e: any) => e.id == action.data.id)
      let currentFunnelSteps = aux.currentFunnel.steps.filter((e: any) => e.id != action.data.id)
      if (currentStep) {
        aux.currentFunnel.steps = [...currentFunnelSteps, ...[action.data]]
        aux.currentFunnel.steps.sort((a: any, b: any) => a.stepNumber - b.stepNumber)
      }

      //Alterar tbm na lista de funnels
      let findFunnel = aux.funnels.find((e: any) => e.id == aux.currentFunnel.id)
      if (findFunnel) {
        findFunnel.steps = aux.currentFunnel.steps
      }

      return aux
    }
    case 'delete-funnel-step': {
      let aux = {...data}

      if (!aux.currentFunnel) return aux
      if (!action.data?.id) return aux

      aux.currentFunnel.steps = aux.currentFunnel.steps.filter(
        (e: any) => e?.id != action?.data?.id
      )

      //Alterar tbm na lista de funnels
      let findFunnel = aux.funnels.find((e: any) => e.id == aux.currentFunnel.id)
      if (findFunnel) {
        findFunnel.steps = aux.currentFunnel.steps
      }

      return aux
    }
    case 'set-filters-data': {
      let aux = {...data}

      aux.filters = action?.data

      return aux
    }
    case 'set-status-card': {
      let aux = {...data}
      console.log('aux', aux)
      for (let i = 0; i < aux?.currentStepsCards?.length; i++) {
        let step = aux.currentStepsCards[i]
        for (let j = 0; j < step?.cards?.length; j++) {
          if (step.cards[j]?.id == action?.data?.id) {
            step.cards[j] = action?.data
            break
          }
        }
      }

      return aux
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
